
 
<template>
  <div ref="mapContent" style="height: 500px; width: 100%; position: relative">
    <!-- <l-map style="height: 500px; width: 100%" :center="this.MAP_CENTER" :zoom="zoom" class="map" ref="map" -->

    <!--  :show="showLoading" -->
    <b-overlay :show="showLoading" rounded="sm" no-wrap>
    </b-overlay>
    <l-map style="height: 500px; width: 100%" :center="center" :zoom="zoom" class="map" ref="map"
      @update:zoom="zoomUpdated" @update:center="centerUpdated">
      <l-tile-layer :url="url">
      </l-tile-layer>
      <MarkerMaps :DATA_STATION1="DATA_STATION1" v-for="marker in this.DATA_STATION1" :key="marker.id" :marker="marker">
      </MarkerMaps>
    </l-map>
  </div>
</template>

<script>
import {
  LMap, LTileLayer, LPopup,
  LTooltip
} from 'vue2-leaflet';
import MarkerMaps from './MarkerMaps'
import 'leaflet/dist/leaflet.css';
import axios from "axios";
import helper from "@/api/helper";
import { latLng, icon } from "leaflet";
import { BOverlay } from "bootstrap-vue";
export default {
  components: {
    LMap,
    LTileLayer, LPopup,
    LTooltip,
    MarkerMaps,
    BOverlay,
  },
  data() {
    return {
      showLoading: false,
      DATA_STATION1: {},
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 5,
      center: [-2.7423878, 118.9871671],
      markerLatLng: [-2.7423878, 118.9871671],
      circle: {
        center: [-2.7423878, 118.98716714],
        radius: 4500,
        color: "#EA5455",
      },
      MAP_CENTER: latLng(-2.7423878, 118.9871671),
      // MAP_CENTER: [],

      markers: [
        { id: 1, namaStasiun: "Stasiun Maimun Saleh", imageUrl: 'https://img.icons8.com/doodle/48/000000/fish-food--v1.png', coordinates: [5.87413, 95.3397] },
        { id: 2, namaStasiun: "Stasiun Soekarno Hatta", imageUrl: 'https://img.icons8.com/doodle/48/000000/pizza--v1.png', coordinates: [-6.125556, 106.655830] },
        { id: 3, namaStasiun: "Stasiun Papua", imageUrl: 'https://img.icons8.com/doodle/48/000000/croissant--v1.png', coordinates: [-4.907442, 140.629501] },
        { id: 4, namaStasiun: "Stasiun Supadio", imageUrl: 'https://img.icons8.com/doodle/48/000000/the-toast--v2.png', coordinates: [-0.144971, 109.407788] },
        {
          id: 5, namaStasiun: "Stasiun Sam Ratulangi", imageUrl: 'https://img.icons8.com/doodle/48/000000/hamburger.png', coordinates: [1.460126, 124.826347]
        },
      ]
    }
  },
  mounted() {
    this.buatMaker()
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {

      // this.center = this.MAP_CENTER;
      this.center = center;
    }, innerClick() {
      alert("Click!");
    },
    async buatMaker(marker) {

      var ids;
      let userLogin = JSON.parse(localStorage.getItem('userLogin'))
      this.showLoading = true;

      if (userLogin.hasOwnProperty('groupIds')) {
        ids = userLogin.groupIds

      }
      else {
        this.showLoading = false;

      }

      let group = userLogin.groupIds
      var url = helper.getBaseUrl() + `@aggregation_multiquery?type_name=BmkgStation&station_id=${group}&_metadata=station_id,path,station_name,alias_station_id,kabupaten_name,propinsi_name,region_description,current_longitude,current_latitude&_size=20000`
      let data_station = []

      await axios
        .get(url, helper.getConfig())
        .then((response) => {
          const { data } = response;
          data_station = data;
        })
        .catch(error => console.log(error))

      // START MAP ROOT
      let list_all_station = JSON.parse(localStorage.getItem('list_all_station'))
      this.showLoading = true;
      const filteredByWmo = list_all_station.reduce((a, o) => (o.station_wmo_id !== 0 && a.push(o.station_id), a), [])

      var urlStation = helper.getBaseUrl() + `@aggregation_multiquery?type_name=BmkgStation&station_wmo_id__not=0&_metadata=station_id,station_name,region_description,propinsi_name,kabupaten_name,current_longitude,current_latitude,path&station_id=${filteredByWmo}&_size=1000&station_id_gte=1&station_id_lte=500`
      let root_station = []
      await axios
        .get(urlStation, helper.getConfig())
        .then((response) => {
          const { data } = response;
          root_station = data;
          // resolve(data_station);
          console.log("root_station >>>>", root_station)
        })
        .catch(error => console.log(error))

      // END MAP ROOT

      this.DATA_STATION = data_station;
      let ubah_data = this.DATA_STATION
      let arr_data = [];

      for (var i in ubah_data) {
        let row_data = {
          station_id: ubah_data[i].station_id,
          station_name: ubah_data[i].station_name,
          region_description: ubah_data[i].region_description,
          propinsi_name: ubah_data[i].propinsi_name,
          kabupaten_name: ubah_data[i].kabupaten_name,
          current_latitude: Number(ubah_data[i].current_latitude),
          current_longitude: Number(ubah_data[i].current_longitude),
          position: [Number(ubah_data[i].current_latitude), Number(ubah_data[i].current_longitude)]

        };
        arr_data.push(row_data);
      }

      this.DATA_STATION_ROOT = root_station;
      let ubah_data_root = this.DATA_STATION_ROOT
      let arr_data_root = [];

      for (var i in ubah_data_root) {
        let row_data = {
          station_id: ubah_data_root[i].station_id,
          station_name: ubah_data_root[i].station_name,
          region_description: ubah_data_root[i].region_description,
          propinsi_name: ubah_data_root[i].propinsi_name,
          kabupaten_name: ubah_data_root[i].kabupaten_name,
          current_latitude: Number(ubah_data_root[i].current_latitude),
          current_longitude: Number(ubah_data_root[i].current_longitude),
          position: [Number(ubah_data_root[i].current_latitude), Number(ubah_data_root[i].current_longitude)]
        };
        arr_data_root.push(row_data);
      }

      let userRoot = JSON.parse(localStorage.getItem('userLogin'))
      let rootId = userRoot.id


      if (rootId === 'root') {
        this.DATA_STATION1 = arr_data_root
      }
      else {
        this.DATA_STATION1 = arr_data
      }
      let maxLatitude = Math.max(...this.DATA_STATION1.map(o => o.current_latitude))
      let minLatitude = Math.min(...this.DATA_STATION1.map(o => o.current_latitude))
      let maxLongitude = Math.max(...this.DATA_STATION1.map(o => o.current_longitude))
      let minLongitude = Math.min(...this.DATA_STATION1.map(o => o.current_longitude))

      this.SET_MARKERS = marker;
      var latMax = maxLatitude;
      var latMin = minLatitude;
      var longMax = maxLongitude;
      var longMin = minLongitude;
      for (var i in marker) {
        var infoMarker = marker[i];
        if (latMin == 0) {
          latMax = infoMarker.position[0];
          latMin = infoMarker.position[0];

          longMax = infoMarker.position[1];
          longMin = infoMarker.position[1];
        }

        if (latMax < infoMarker.position[0]) {
          latMax = infoMarker.position[0];
        }

        if (latMin > infoMarker.position[0]) {
          latMin = infoMarker.position[0];
        }

        if (longMax < infoMarker.position[1]) {
          longMax = infoMarker.position[1];
        }

        if (longMin > infoMarker.position[1]) {
          longMin = infoMarker.position[1];
        }
      }
      var centerMapLat = (latMax + latMin) / 2;
      var centerMapLong = (longMax + longMin) / 2;

      this.MAP_CENTER = latLng(centerMapLat, centerMapLong);
      this.showLoading = false;
    },
  }
}
</script>

<style>
.map {
  position: absolute;
  width: 100%;
  height: 500px;
  overflow: hidden;
  z-index: 1;

}
</style> 